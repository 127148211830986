import React from "react";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const Header = ({ profile }) => {
	const [isShow, setShow] = useState(false);
	return (
		<div className="header">
			<div className="header-top">
				<div className="logo">
					<Link to="/">
						<img src="/logo.png" alt="Logo" />
						{/*<span
							style={{
								color: "#fff",
								fontSize: "24px",
								fontWeight: "bold",
								position: "relative",
								top: "-10px",
							}}
						>
							LOTO99
						</span>*/}
					</Link>
				</div>
				<div className="header-right">
					<div style={{ display: "flex", float: "right" }}>
						{profile ? (
							<div style={{ display: "flex", float: "right" }}>
								<span style={{ marginRight: "0.111rem" }}>
									Số dư:{" "}
									<b>
										{isShow
											? Math.floor(profile.money).toLocaleString()
											: "*******"}
										đ
									</b>
									{isShow ? (
										<VisibilityOff onClick={() => setShow(!isShow)} />
									) : (
										<Visibility onClick={() => setShow(!isShow)} />
									)}
								</span>
							</div>
						) : (
							<div className="button-top">
								<Link to="/login" className="btn-login">
									Đăng nhập
								</Link>
								<Link to="/register" className="btn-register">
									Đăng ký
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
